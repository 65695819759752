@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,900;1,100;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500&display=swap');

body {
  background-color: #f7f6f2;
  color: rgb(40, 40, 40) !important;
  font-size: '1.4rem' !important;
}
h4 {
  font-size: 32px !important;
  margin-bottom: 5px;
}

li {
  padding: 0 !important;
}
.MuiBox-root:last-child {
  border-bottom: 'none';
}
.App {
  font-family: 'Raleway', monospace !important;
}

.Timeline,
h4,
ul,
li,
.css-n2itgq-MuiTypography-root-MuiTimelineOppositeContent-root {
  font-family: 'Raleway', monospace !important;
}

.App-logo {
  height: 100px;
  pointer-events: none;
}

.MuiTypography-root,
.body1,
div {
  font-family: 'Raleway', monospace !important;
}

.MuiTimelineConnector-root {
  /* Track */
  animation: 'badge-color-cycle 32s linear infinite';
}

.MuiTimelineDot-root {
  animation: badge-color-cycle 32s linear infinite !important;
  box-shadow: 0px 6.03805px 6.03805px rgba(0, 0, 0, 0.25);
}

.App-header {
  position: relative;
  padding: 20px;
  background-color: white;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiListItemIcon-root svg {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes badge-color-cycle {
  0% {
    background-color: #ffda79;
  }

  25% {
    background-color: #ffabe7;
  }

  50% {
    background-color: #d2bcf3;
  }

  75% {
    background-color: #edf3d8;
  }

  100% {
    background-color: #ffda79;
  }
}

.swiper {
  width: 240px;
  height: 320px;
  z-index: 0;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: black;
  border: 3px solid white;
}

.swiper-slide:nth-child(1n) {
  animation: badge-color-cycle 32s linear infinite !important;
}

.swiper-slide:nth-child(2n) {
  animation: badge-color-cycle 32s linear infinite !important;
}

.swiper-slide:nth-child(3n) {
}

.swiper-slide:nth-child(4n) {
  animation: badge-color-cycle 32s linear infinite !important;
}

.swiper-slide:nth-child(5n) {
}

.swiper-slide:nth-child(6n) {
  animation: badge-color-cycle 32s linear infinite !important;
}

.swiper-slide:nth-child(7n) {
}

.swiper-slide:nth-child(8n) {
  animation: badge-color-cycle 32s linear infinite !important;
}

.swiper-slide:nth-child(9n) {
  animation: badge-color-cycle 32s linear infinite !important;
}

.swiper-slide:nth-child(10n) {
}
